<template>
  <a-drawer
    width="800px"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-row>
        <a-col :span="12">

          <a-form-model-item label="设备类型" prop="typeId">
            <a-select v-model="form.typeId" placeholder="请选择" style="width: 300px">
              <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="添加电信平台" prop="sfDx">
            <a-select v-model="form.sfDx" placeholder="请选择" style="width: 300px">
              <a-select-option v-for="(item,key) in [{name:'否',val:0},{name:'是',val:1}]" :key="key" :value="item.val">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备名称" prop="devName">
            <a-input v-model="form.devName" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="设备编号" prop="devCode">
            <a-input v-model="form.devCode" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="iccid" prop="iccid">
            <a-input v-model="form.iccid" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="水表编号" prop="devSn">
            <a-input v-model="form.devSn" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="口径" prop="caliber">
            <a-input v-model="form.caliber" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="设备配价">
            <a-select v-model="form.devOrdinateId" placeholder="请选择" style="width: 300px">
              <a-select-option v-for="(item,key) in devOrdinateList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item label="经度" prop="log">
            <a-input v-model="form.log" type="number" placeholder="请在地图上选择位置" style="width: 300px" disabled/>
          </a-form-model-item>
          <a-form-model-item label="纬度" prop="lat">
            <a-input v-model="form.lat" type="number" placeholder="请在地图上选择位置" style="width: 300px" disabled/>
          </a-form-model-item> -->
        </a-col>
        <a-col :span="12">
          <!-- <a-form-model-item label="省份" prop="provinceCode">
            <a-input v-model="form.provinceCode" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="城市" prop="cityCode">
            <a-input v-model="form.cityCode" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="区/县" prop="areaCode">
            <a-input v-model="form.areaCode" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="小区名称" prop="community">
            <a-input v-model="form.community" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="楼栋" prop="building">
            <a-input v-model="form.building" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="单元" prop="houseUnit">
            <a-input v-model="form.houseUnit" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="门牌号" prop="houseNumber">
            <a-input v-model="form.houseNumber" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item> -->
          <a-form-model-item label="区域信息" prop="value">
            <a-tree-select
              v-model="form.value"
              show-search
              style="width: 300px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择"
              allow-clear
              @select="select"
              tree-default-expand-all>
              <a-tree-select-node v-for="item1 in deptOptions" :key="item1.id" :value="item1.id" :title="item1.label" :selectable="false">
                <a-tree-select-node v-for="item2 in item1.children" :key="item2.id" :value="item2.id" :title="item2.label" :selectable="false">
                  <a-tree-select-node v-for="item3 in item2.children" :key="item3.id" :value="item3.id" :title="item3.label" :selectable="false">
                    <a-tree-select-node v-for="item4 in item3.children" :key="item4.level" :value="item4.id" :title="item4.label">
                      <a-tree-select-node v-for="item5 in item4.children" :key="item5.level" :value="item5.id" :title="item5.label"/>
                    </a-tree-select-node>
                  </a-tree-select-node>
                </a-tree-select-node>
              </a-tree-select-node>
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="houseDetail">
            <a-input v-model="form.houseDetail" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="绑定人名称" prop="userName">
            <a-input v-model="form.userName" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="绑定人手机号" prop="userPhone">
            <a-input v-model="form.userPhone" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="绑定人身份证号" prop="userIccid">
            <a-input v-model="form.userIccid" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="用户类型" prop="devUserType">
            <a-input v-model="form.devUserType" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remark">
            <a-input v-model="form.remark" placeholder="请输入" style="width: 300px"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- <a-form-model-item label="选择位置">
        <AmapSearch ref="map" height="300px" @getInfo="getAddress" />
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { areaList } from '@/api/pages/region'
import { deviceInfo, deviceAdd, deviceEdit, deviceTypeList, deviceOrdinateList } from '@/api/pages/device'
    // import AmapSearch from '@/components/AmapSearch'
export default {
  name: 'CreateForm',
    // components: { AmapSearch },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      deptOptions: [],
      value: null,
      statusOptions: [
        {
          label: '表端预付费',
          value: 1
        },
        {
          label: '平台预付费',
          value: 0
        }
      ],
      typeList: [],
      devOrdinateList: [],
      // 表单
      form: {
        id: 0,
        sfDx: 0,
        buildingId: 0,
        caliber: '',
        communityId: 0,
        devCode: '',
        devName: '',
        devOrdinateId: undefined,
        devSn: '',
        devUserType: '',
        deviceAreaCityId: 0,
        deviceAreaId: 0,
        deviceAreaProvinceId: 0,
        houseDetail: '',
        iccid: '',
        remark: '',
        typeId: undefined,
        userIccid: '',
        userName: '',
        userPhone: '',
        value: ''
      },
      open: false,
      rules: {
        devCode: [{ required: true, message: '设备编号不能为空', trigger: 'blur' },
          { min: 14, max: 14, message: '长度为14位', trigger: 'blur' }],
        devSn: [{ required: true, message: '水表编号不能为空', trigger: 'blur' },
          { min: 14, max: 14, message: '长度为14位', trigger: 'blur' }],
        typeId: [{ required: true, message: '请选择设备类型', trigger: ['blur', 'change'] }],
        value: [{ required: true, message: '请选择区域信息', trigger: ['blur', 'change'] }],
        sfDx: [{ required: true, message: '请选择是否需要添加电信平台', trigger: ['blur', 'change'] }]
      }
    }
  },
  filters: {
  },
  created () {
    deviceTypeList().then(response => {
      const list = response.data
      list.map(item => {
        if (item.name === '485水表') {
          this.typeList.push(item)
        }
      })
    })
    deviceOrdinateList().then(response => {
      this.devOrdinateList = response.data
    })
    /** 查询区域下拉树结构 */
    areaList().then(response => {
      this.deptOptions = response.data
    })
  },
  computed: {
  },
  watch: {
  },
  methods: {
    select(value, node, e) {
      // console.log(value, node, e)
      console.log(value, e.selectedNodes[0].data.key)
      const level = e.selectedNodes[0].data.key
      if (level === 4) {
        this.form.buildingId = 0
        this.form.communityId = value
      } else if (level === 5) {
        this.form.buildingId = value
        this.form.communityId = 0
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: 0,
        sfDx: 0,
        buildingId: 0,
        caliber: '',
        communityId: 0,
        devCode: '',
        devName: '',
        devOrdinateId: undefined,
        devSn: '',
        devUserType: '',
        deviceAreaCityId: 0,
        deviceAreaId: 0,
        deviceAreaProvinceId: 0,
        houseDetail: '',
        iccid: '',
        remark: '',
        typeId: undefined,
        userIccid: '',
        userName: '',
        userPhone: '',
        value: undefined
      }
    },
    getAddress(detail) {
      console.log(detail)
      this.form.lat = detail.latitude
      this.form.log = detail.longitude
      this.form.provinceCode = detail.province
      this.form.cityCode = detail.city
      this.form.areaCode = detail.country
      this.$forceUpdate()
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      setTimeout(() => {
        this.form.typeId = this.typeList[0].id
      }, 500)
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      deviceInfo({ id: configId }).then(response => {
        this.form = response.data
        // this.$nextTick(() => {
        //   this.$refs.map.mapInit(response.data.log, response.data.lat)
        // })
        if (response.data.buildingId && response.data.buildingId !== '0' && response.data.buildingId !== 0) {
          this.form.value = response.data.buildingId
        } else if (response.data.communityId && response.data.communityId !== '0' && response.data.communityId !== 0) {
          this.form.value = response.data.communityId
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form)
      this.form.yearStart = parseInt(this.form.yearStart)
      this.form.yearEnd = parseInt(this.form.yearEnd)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            deviceEdit(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            deviceAdd(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
@/api/pages/device
